import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from "react";
import './index.css'
import TwinPane from "../TwinPane";
import { pTranslationArray } from '../../helpers/Helpers';
import { ActionTypes, AnnotationTypes } from '@smarterservices/twilio-sync-client';
import * as icons from '../Icons'

export default function RoomScan(props) {
  const { t: translation } = useTranslation();
  const [recording, setRecording] = useState(false);
  const [finished, setFinished] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const videoStream = useRef(null);
  const {syncClient, setNextEnabled} = props;
  const actionTypes = new ActionTypes();

  const startWebcamStream = () => {
    syncClient.addAction(actionTypes.session.createAnnotation(AnnotationTypes.roomScanStart()));
    const constraints = {
      video: { deviceId },
      audio: false
    }
    navigator.mediaDevices.getUserMedia(constraints)
    .then(stream => {
      videoStream.current = stream;
      const video = document.getElementById('video');
      video.srcObject = stream;
      video.onloadedmetadata = () => {
          video.play()
        }
    })
  }

  const stopWebcamStream = () => {
    syncClient.addAction(actionTypes.session.createAnnotation(AnnotationTypes.roomScanStop()));
    const tracks = videoStream.current.getTracks();

    tracks.forEach(track => track.stop());
    videoStream.current = null;
  }

  const recordingButton = () => {
    if (recording) {
      return (
        <button
            className='btn'
            onClick={() => {
                setFinished(true);
                setRecording(false);
                stopWebcamStream();
              }
            }
          >
            {translation('room_scan.buttons.stop_recording')}
          </button>
      )
    } else if (finished) {
      return (
        <button
            className='btn'
            onClick={() => {
                setFinished(false);
              }
            }
          >
            {translation('room_scan.buttons.retake')}
          </button>
      )
    } else {
      return (
        <button
            className='btn'
            onClick={() => {
                setRecording(true);
                startWebcamStream();
              }
            }
          >
            {translation('room_scan.buttons.start_recording')}
          </button>
      )
    }
  }

  useEffect(()=> {
    setNextEnabled(finished)
  })

  useEffect(() => {
    const getSyncDoc = async () => {
      if(syncClient) {
        const syncDoc = await syncClient.getSync();
        let videoDeviceObj = {};
        try {
            videoDeviceObj = JSON.parse(syncDoc.videoDeviceId);
        } catch (error) {
            videoDeviceObj.deviceId = '';
            console.error('Error parsing JSON:', error);
        }
        setDeviceId(videoDeviceObj?.deviceId);
      }
    }

    getSyncDoc()
  }, [syncClient])

  useEffect(() => {
    syncClient.setSync({currentStep: "RoomScan"});
    //eslint-disable-next-line
  },[])

  const renderLeftPane = () => {
    return (
      <div className="body">
        {pTranslationArray(translation('room_scan.left_pane.content'))}

        <h2>{translation('room_scan.left_pane.steps.0')}</h2>
        {pTranslationArray(translation('room_scan.left_pane.step_1'))}

        <h2>{translation('room_scan.left_pane.steps.1')}</h2>
        {pTranslationArray(translation('room_scan.left_pane.step_2'))}

        <h2>{translation('room_scan.left_pane.steps.2')}</h2>
        {pTranslationArray(translation('room_scan.left_pane.step_3'))}

      </div>
    )
  }
  const renderRightPane = () => {
    return (
      <div className="recording column">
        {recording &&
        <figure className='webcam'>
          <video id='video' />

          {recordingButton()}
          </figure>
        }
        {finished &&
            <div className='column room-scan'>
              <icons.iconCheckCircle />
              <h2>{translation('room_scan.scan_successful')}</h2>
            </div>
        }
        {!recording && recordingButton()}

      </div>
    )
  }


  return (
    <>
      <TwinPane
        leftPane={renderLeftPane()}
        rightPane={renderRightPane()}
      ></TwinPane>
    </>
  )

}
/*global chrome*/
import { useCallback, useEffect, useRef, useState } from 'react';
import { config } from '../../config'
import { TwilioSyncClient, ActionTypes, AnnotationTypes, ModalityOptions } from '@smarterservices/twilio-sync-client';
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode"
import LogRocket from 'logrocket';
import * as atatus from 'atatus-spa';

import './index.css';
import * as icons from '../../icons/Icons';
import {
  getModalityOptions,
  putSessionInProgress,
  startSystemCheck,
  completeSystemCheck,
  getSessionStartData,
  getExamConfig,
  sendLocationIdentifiers
} from './api';
import {checkPermissions, checkBrowser, pinTabs, checkExtension, getOperatingSystem} from "../../helpers/Helpers";
import { LaunchDarkly, FeatureFlag } from 'react-launch-darkly'
import {PostHogProvider, usePostHog} from "posthog-js/react";

// Child Components
import ExtensionCheck from '../ExtensionCheck';
import ChromePermissionCheck from '../ChromePermissionCheck';
import DisplayCheck from '../DisplayCheck';
import UtilityAppCheck from '../UtilityAppCheck';
import AudioCheck from '../AudioCheck';
import CameraCheck from '../CameraCheck';
import ScreenSharing from '../ScreenSharing';
import PreparingSession from '../PreparingSession';
import FaceVerification from '../FaceVerification';
import IdentityVerification from '../IdentityVerification';
import RoomScan from '../RoomScan';
import ProctorReview from '../ProctorReview';
import Zendesk from '../Zendesk';
import Salesforce from '../Salesforce';
import AgreementsCheck from "../AgreementsCheck";
import MacPermissionCheck from "../MacPermissionCheck";
import SessionStatusError from "../SessionStatusError";
import ExtensionError from "../ExtensionError";
import TestingInfo from '../TestingInfo';
import ComputerCheck from '../ComputerCheck';
import SpeedTest from "../SpeedTest";
/**
 * The stepper component maintains the state of onboarding, while
 * providing a convienience method for the current step to call
 * when ready to move to the next step.
 *
 */
 export default function Stepper() {
  const firstRender = useRef(true);
  let [ currentStep, setCurrentStep ] = useState(0);
  const [ syncClient, setSyncClient ] = useState(null);
  const [syncDoc, setSyncDoc] = useState(undefined);
  const [ nextEnabled, setNextEnabled ] = useState(false);
  const [ steps, setSteps ] = useState(null);
  const [ loading, _setLoading ] = useState(['empty', 'whiteout']);
  const [ whiteout, setWhiteout ] = useState(true);
  const [logRocketInit, setLogRocketInit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [ systemCheckComplete, setSystemCheckComplete ] = useState(false);
  const [errorDescription, setErrorDescription] = useState('An error has occurred. Please contact support@smarterproctoring.com if this persists.');
  const [ resolutionBand, setResolutionBand ] = useState('High');
  const [ userData, setUserData ] = useState({});
  const [ studentNotes, setStudentNotes ] = useState('');
  const [ token, setToken ] = useState(null);
  const [ installSid, setInstallSid ] = useState(null);
  const [ examSid, setExamSid ] = useState(null);
  const [ sessionSid, setSessionSid ] = useState(null);
  const [ sessionStatus, setSessionStatus ] = useState(null);
  const [ extensionError, setExtensionError ] = useState(null);
  const [ shouldLaunchExam, setShouldLaunchExam ] = useState(false);
  const supportRendered = useRef(false);
  const errorCallback = useRef(null);
  const lastFocused = useRef(null);
  const permissionsRef = useRef();
  const displayRef = useRef();
  const screenShareRef = useRef();
  const preparingRef = useRef();
  const reviewRef = useRef();
  const utilityRef = useRef();
  const [removedSteps, _setRemovedSteps] = useState([]);

  const removedStepsRef = useRef(removedSteps);
  const loadingRef = useRef(loading);

  const posthog = usePostHog();

  const setRemovedSteps = (data) => {
    if(removedStepsRef.current.indexOf(data) === -1){
      removedStepsRef.current.push(data);
      _setRemovedSteps(removedStepsRef.current);
    }
  }

  const removeNonHybridSteps = () => {
      setRemovedSteps('id_verification.title');
      setRemovedSteps('face_verification.title');
      setRemovedSteps('room_scan.title');
      setSteps(steps => {
          for(let title of removedSteps){
              const index = steps.findIndex(step => step.title === title);
              if(index > -1) {
                  steps.splice(index, 1);
              }
          }
          return steps
      });
  };

  const addNonHybridSteps = () => {
    _setRemovedSteps((removedSteps) => {
      const newArr = [...removedSteps];

      const idIndex = newArr.indexOf('id_verification.title');
      if (idIndex !== -1) newArr.splice(idIndex, 1);

      const faceIndex = newArr.indexOf('face_verification.title');
      if (faceIndex !== -1) newArr.splice(faceIndex, 1);

      const scanIndex = newArr.indexOf('room_scan.title');
      if (scanIndex !== -1) newArr.splice(scanIndex, 1);

      return newArr;
    })
  }

  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  }

  const { t: translation } = useTranslation();
  const actionTypes = new ActionTypes();

  // This gets the query params, if any, from the url
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const decodedToken = jwt_decode(params.token);
  const uiOnlyTest = params.testOnboardingUiOnly;
  const systemCheck = params.systemCheck;

  atatus.config('e01bc7081fec407f8af5d9fa7476610f', {
    console: true,
    consoleTimeline: true,
    customData: {
        sessionSid: sessionSid,
        installSid: installSid,
        token: decodedToken,
        examSid: examSid
    }
}).install();

  const lockModalFocus = useCallback((event) => {
    event.preventDefault();
    const description = document.getElementById('modal-description');
    const button = document.getElementById('close-modal');

    // Key code 9 is tab
    if (event.which === 9) {
      document.activeElement === description ? button.focus() : description.focus();
    }

    // key code 27 is escape, key code 13 is enter
    if (event.which === 27 || (event.which === 13 && document.activeElement === button)) {
      closeError();
    }
  }, [])

  // This function is used to be passed into children components
  // so onboarding can advance, and the child components don't need
  // to worry about the overall workflow.
  const nextStep = () => {
    const stepThatClickedNext = steps[currentStep];
    if (stepThatClickedNext.title === 'extension_check.title') {
        window.location.reload();
    }
    setNextEnabled(false);
    setCurrentStep(++currentStep);
    const redirectIfOnboardingComplete = async () => {
      if (systemCheck) {
        setSystemCheckComplete(true)
        completeSystemCheck(installSid, examSid, sessionSid, token);
      } else {
        const syncDoc = await syncClient.getSync();
        const examUrl = syncDoc?.sessionStart?.exam?.url;
        const actions = [
          actionTypes.session.createAnnotation(AnnotationTypes.onboardingComplete(examUrl)),
          actionTypes.extension.setSessionInProgress()
        ];
        syncClient.addActions(actions);
        await putSessionInProgress(sessionSid, token);

        let examDeliverySystems;
        //Check if exam has an examDeliverySystem attached, if not pull all and try to match on url regEx
        if(!userData.exam.configuration.examDeliverySystem){
           examDeliverySystems = await sendLocationIdentifiers(installSid, token)
        } else {
          examDeliverySystems = [userData.exam.configuration.examDeliverySystem]
        }
        if (chrome.runtime) {
          chrome.runtime.sendMessage(
              config.spExtensionId,
              { message: "sp_background__locationIdentifiers", locationIdentifiers: examDeliverySystems}
          );
        }
        window.location.replace(examUrl);
      }
    }

    // If we've gotten through the last step, redirect to the exam url.
    if(shouldLaunchExam || currentStep >= steps.length) {
      setLoading([...loading, 'steps']);
      redirectIfOnboardingComplete();
      setLoading(loading.filter(item => item !== 'steps'));
    }
  }

  /**
   * throwError - Shows an error modal to the user
   * @param {string} [description] - The description for the error message
   * @param {function} [callback] - Callback function to be ran when the error modal is closed
   */
  const throwError = (description, callback) => {
    // Saves the last element a user was focused on before the error modal pops up
    lastFocused.current = document.activeElement;

    if(typeof description === 'string') {
      setErrorDescription(description)
    }

    if(callback) {
      errorCallback.current = callback
    }
    setShowError(true);
  }

  /**
   * closeError - Resets error description to default and runs the error callback if we have one
   */
  const closeError = () => {
    setShowError(false);
    setErrorDescription('An error has occurred. Please contact support@smarterproctoring.com if this persists.');

    if(typeof errorCallback.current === 'function') {
      errorCallback.current();
    }
    errorCallback.current = null;

    // Focus the element that was focused before the modal showed up
    if (lastFocused.current) {
      lastFocused.current.focus();
      lastFocused.current = null;
    }
  }

  const quietCloseExtension = () => {
      // We cannot add this as an action, because this may be from a different sessionSid, so the action would not go into
      // the proper twilio sync doc
      if (chrome.runtime) {
          chrome.runtime.sendMessage(
              config.spExtensionId,
              { message: "sp_background__quietCloseExtension"}
          );
      }
  }

  const renderSupportChat = (salesforce) => {
    if (!supportRendered.current && Object.keys(userData).length > 0) {
      supportRendered.current = true;

      if (salesforce) {
        return <Salesforce userData={userData} params={params} examSid={examSid} decodedToken={decodedToken} token={params.token} />
      } else {
        return <Zendesk syncDoc={syncDoc} />
      }
    }
  };

  const hideSupportChat = () => {
    if (window.hideHelpCenter) {
      window.hideHelpCenter();
    };
  }

  const initializeLogRocket = () => {
    if(logRocketInit === false) {
      LogRocket.init(config.logRocket);
      setLogRocketInit(true);
    }
  }

  let environment = 'develop';
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    environment = 'production'
  }

  const ldConfig = {
    key: decodedToken.userSid,
    custom: {
      domain: window.location.hostname,
      environment,
      role: 'learner',
      installSid,
      deploymentSid: decodedToken.deploymentSid,
      enrollmentSid: decodedToken.enrollmentSid,
      courseSid: decodedToken.courseSid,
      // integrationSid: userData?.integrationSid,
      userAgent: navigator.userAgent
    }
  }

  useEffect(() => {
    if (!installSid || !examSid || !sessionSid || !token) {
        return;
    }
    if (systemCheck) {
      startSystemCheck(installSid, examSid, sessionSid, token);

      setRemovedSteps('agreements_check.title');
      setRemovedSteps('screen_sharing.title')
      setRemovedSteps('preparing_session.title');
      setRemovedSteps('face_verification.title');
      setRemovedSteps('id_verification.title');
      setRemovedSteps('room_scan.title');
      setRemovedSteps('proctor_review.title');
    }

    const handleModalityOptions = async () => {
      const response = await getModalityOptions(installSid, examSid, sessionSid, token);
      const { workflowModule, modalityOptions } = response;
      const optionsToApply = [];

      if (!workflowModule.includes('hybrid')) {
        setRemovedSteps('proctor_review.title');
      }

      // Verification modality options
      if (!modalityOptions.roomScan) setRemovedSteps('room_scan.title');
      if (!modalityOptions.verifyId) {
        setRemovedSteps('id_verification.title');
        setRemovedSteps('face_verification.title');
      }
      if (!modalityOptions.desktopAppCheck) {
        setRemovedSteps('utility_app_check.title');
        setRemovedSteps('computer_check.title');
      }
      if (!modalityOptions.onlyOneScreen) setRemovedSteps('display_check.title');

      let additionalWhitelistedExtensions = [];

      if(modalityOptions.whitelistedExtensions && Array.isArray(modalityOptions.whitelistedExtensions)){
        additionalWhitelistedExtensions = modalityOptions.whitelistedExtensions;
      }

      optionsToApply.push(ModalityOptions.blockExtensions(additionalWhitelistedExtensions));
      if (modalityOptions.disableClipboard) optionsToApply.push(ModalityOptions.disableClipboard());
      if (modalityOptions.disableRightClick) optionsToApply.push(ModalityOptions.disableRightClick());
      if (modalityOptions.disablePrinting) optionsToApply.push(ModalityOptions.disablePrinting());
      if (modalityOptions.onlyOneScreen) optionsToApply.push(ModalityOptions.enforceSingleDisplay());
      if (optionsToApply.length > 0) {
          setTimeout(function() {
              syncClient.addAction(actionTypes.extension.applyModalityOptions(optionsToApply));
          }, 5000);
      }

    }
    if(getOperatingSystem(window) !== 'MacOS') {
      setRemovedSteps('mac_permission_check.title')
    }

    const getSyncDoc = async () => {
      const syncDoc = await syncClient.getSync();
      setSyncDoc(syncDoc);

      if (syncDoc.agreementsComplete === true){
        setRemovedSteps("agreements_check.title")
      }
    }

    const getPermissions = async () => {
      const syncDoc = await syncClient.getSync();
      syncClient.addAction(actionTypes.extension.getAllowedPermissions(syncDoc.sessionStart.exam.url, 'onboarding'));
    }

    if(syncClient && firstRender.current) {

        firstRender.current = false;

        handleModalityOptions().then(()=> {
          getSyncDoc()
        })
        getPermissions()

        syncClient.getEventEmitter().on("spSyncDocUpdated", syncEmitterCallback);
        syncClient.getEventEmitter().on('onboardingActionsAdded', emitterCallback);


        syncClient.addAction(actionTypes.extension.getDisplayCount('onboarding'));
    }

    //eslint-disable-next-line
  }, [actionTypes.extension, syncClient, installSid, examSid, sessionSid, token]);

  useEffect(() => {
    if (!params.installSid || !params.sessionSid || !params.examSid || !params.token) {
        return;
    }
    const extensionStatus = async () => {
        return new Promise((resolve, reject) => {
            const showExtensionCheckPage = () => {
                const isChrome = checkBrowser();
                setLoading(loading.filter(item => item !== 'whiteout'));
                setSteps([
                    { component: <ExtensionCheck params={params} setLoading={setLoading} chromeBrowser={isChrome} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "extension_check.title" }
                ]);
                setCurrentStep(0);
            };
            let chromeExists = false;
            try {
                if (chrome.runtime) {
                    chromeExists = true;
                }
            } catch(error) {
                // No Op
            }
            if (chromeExists) {
                try {
                    chrome.runtime.sendMessage(
                        config.spExtensionId,
                        { message: "sp_background__extensionStatus" },
                        (response) => {
                            if (response && response.extensionStatus === 'ENGAGED') {
                                if (response.sessionSid === params.sessionSid) {
                                    setExtensionError(config.extErrorMap.SAME_SESSION_ACTIVE);
                                } else {
                                    setExtensionError(config.extErrorMap.OTHER_SESSION_ACTIVE);
                                }
                                resolve(false);
                            } else {
                                checkExtension().then((isValid) => {
                                    if (isValid) {
                                        pinTabs(params, systemCheck);
                                        resolve(true);
                                    } else {
                                        showExtensionCheckPage();
                                        resolve(false);
                                    }
                                });
                            }
                        });
                } catch (error) {
                    showExtensionCheckPage();
                    resolve(false);
                }
            } else {
                showExtensionCheckPage();
                resolve(false);
            }
        })
    }
    const getSalesforceData = async () => {
      const sessionStartResponse = await getSessionStartData(params.installSid, params.examSid, params.sessionSid, params.token);
      const status = sessionStartResponse.session.status;
      setSessionStatus(status);
      setUserData(sessionStartResponse);

      if (status !== 'Scheduled') {
          return {
              isSessionReady: false,
              userData: sessionStartResponse
          };
      }

      const isExtensionReady = await extensionStatus();
      if (!isExtensionReady) {
          return {
              isSessionReady: false,
              userData: sessionStartResponse
          };
      }

      const { configuration } = sessionStartResponse.exam;
      const configurationResponse = await getExamConfig(params.installSid, sessionStartResponse.courseSid, params.examSid, configuration.sid, params.token);

      setStudentNotes(configurationResponse.studentNotes);
      setToken(params.token);
      setInstallSid(params.installSid);
      setExamSid(params.examSid);
      setSessionSid(params.sessionSid);
      return {
          isSessionReady: true,
          userData: sessionStartResponse
      };
    }

    getSalesforceData().then(({isSessionReady, userData}) => {
        if (isSessionReady) {
            // 2 seconds to give the onboarding UI a chance to remove any unneeded steps
            setTimeout(() => {
                const overlay = document.querySelector('#loading-overlay');
                overlay.style.opacity = '0%';
                // 200 ms to give overlay time to transition out
                setTimeout(()=> {
                    setWhiteout(false);
                    setLoading(loading.filter(item => item !== 'whiteout'));
                }, 200);
            }, 4000);

            const posthogIdentity = {
                email: userData.user.email,
                name: `${userData.user.firstName} ${userData.user.lastName}`,
                role: 'learner'
            }
            const userSid = userData.user.sid;
            posthog.identify(
                userSid,
                posthogIdentity
            );
            posthog.capture('startOnboarding',
                {
                    $set: { sessionSid: params.sessionSid }
                });
            initializeLogRocket();
            LogRocket.identify(params.sessionSid, {
                examSid: params.examSid,
                token: params.token,
                installSid: params.installSid
            });
        }
    });
    //eslint-disable-next-line
  }, [])

  // This hook will check to make sure the sync client is initialized,
  // and if it is not it will initialize a connection
  useEffect(() => {
    if (!installSid || !examSid || !sessionSid || !token) {
      return;
    }
    let syncOptions = {
      baseUri: config.apiUrl,
      documentId: `Session_${sessionSid}`,
      identity: systemCheck ? `systemCheck_onboarding_${sessionSid}` :`onboarding_${sessionSid}`,
      token: token,
    };

    // Adds an initialData object to sync client initialization so that we
    // can still test every step without all functionality being in place
    if(uiOnlyTest) {
      syncOptions = {
        ...syncOptions,
        initialData: {
          connectedToProctor: true,
          proctorReviewComplete: {result: 'approved', reason: 'uiOnlyTest'},
          faceProcessorStarted: true,
          videoDeviceId: 'default',
          sessionStart: {
            session: {
              sid: "ES6827f6a3c414ab1c06741b12b89a6f46",
              installSid: "AIbde9a05b551d4e169a98d40bc1e59064",
            },
            exam: {
              title: 'Fake Test',
              description: 'This info is all test data',
              sid: "EX0daa68c9e23b4a508b225138a5bf8a83",
              url: "http://www.google.com",
              configuration: {
                duration: 120
              }
            }
          }
        }
      }
    }

    const setupSyncClient = async() => {
        if (!syncClient) {
            setSyncClient(await new TwilioSyncClient(syncOptions))
        }
    }
    setupSyncClient()

    // This is our list of steps, to be rendered in the order of
    // the array.
    //eslint-disable-next-line
    if(syncClient){
      let stepArray = ([
        { component: <ComputerCheck setNextEnabled={setNextEnabled} />, title: 'computer_check.title'},
        { component: <TestingInfo setNextEnabled={setNextEnabled} />, title: 'testing_info.title'},
        { component: <AgreementsCheck syncClient={syncClient} params={params} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "agreements_check.title" },
        { component: <ChromePermissionCheck loading={loading} setLoading={setLoading} ref={permissionsRef} syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "chrome_permission_check.title" },
        { component: <MacPermissionCheck loading={loading} setLoading={setLoading} ref={permissionsRef} syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "mac_permission_check.title" },
        { component: <DisplayCheck ref={displayRef} setNextEnabled={setNextEnabled} throwError={throwError} syncClient={syncClient} />, title: "display_check.title" },
        { component: <SpeedTest syncClient={syncClient} params={params} setNextEnabled={setNextEnabled} throwError={throwError} setResolutionBand={setResolutionBand} />, title: "internet_speed_check.title" },
        { component: <UtilityAppCheck setLoading = {setLoading} ref={utilityRef} loading={loading} params={params} setNextEnabled={setNextEnabled} throwError={throwError} syncClient={syncClient} />, title: "utility_app_check.title" },
        { component: <AudioCheck syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError}  />, title: "audio_check.title" },
        { component: <CameraCheck loading = {loading} setLoading = {setLoading} syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "camera_check.title" },
        { component: <ScreenSharing loading={loading} setLoading={setLoading} ref={screenShareRef} syncClient={syncClient} setNextEnabled={setNextEnabled} resolutionBand={resolutionBand} throwError={throwError} />, title: "screen_sharing.title" },
        { component: <PreparingSession loading={loading} ref={preparingRef} syncClient={syncClient} params={params} studentNotes={studentNotes} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "preparing_session.title" },
        { component: <ProctorReview ref={reviewRef} params={params} syncClient={syncClient} setNextEnabled={setNextEnabled} setShouldLaunchExam={setShouldLaunchExam} removeNonHybridSteps={removeNonHybridSteps} addNonHybridSteps={addNonHybridSteps} throwError={throwError} />, title: "proctor_review.title" },
        { component: <FaceVerification syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "face_verification.title" },
        { component: <IdentityVerification syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "id_verification.title" },
        { component: <RoomScan syncClient={syncClient} setNextEnabled={setNextEnabled} throwError={throwError} />, title: "room_scan.title" }
      ])
      setSteps(() => {
        for(let title of removedSteps){
          const index = stepArray.findIndex(step => step.title === title);
          if(index > -1) {
            stepArray.splice(index, 1);
          }
        }
        return stepArray
      });
    }

    //eslint-disable-next-line
  },[syncClient, token, sessionSid, uiOnlyTest, loading, removedSteps, resolutionBand]);


  const emitterCallback = (response) => {
    response.forEach(actionObj => {
      if (actionObj.action === "getDisplayCount" && currentStep < steps?.findIndex(step => step.title === 'display_check.title')){
          if (actionObj?.response?.displayCount === 1 && removedStepsRef.current.indexOf('display_check.title') === -1) {
            setRemovedSteps('display_check.title');
          }
      } else if(actionObj.action === "getAllowedPermissions"){
          if (permissionsRef.current){
            permissionsRef.current.emitterCallback(actionObj);
          }
          else{
            setLoading([...loadingRef.current, 'stepperPermissions']);
            const missing = checkPermissions(actionObj.response, true);
            if (missing === false && removedStepsRef.current.indexOf('chrome_permission_check.title') === -1){
              setRemovedSteps('chrome_permission_check.title');
            }
            setLoading(loadingRef.current.filter(item => item !== 'stepperPermissions'));
          }
      } else if(actionObj.action === "getAllowedPermissions" && permissionsRef.current){
          permissionsRef.current.emitterCallback(actionObj);
      } else if(actionObj.action === "getDisplayCount" && displayRef.current){
          displayRef.current.emitterCallback(actionObj);
      } else if((actionObj.action === "userSharedScreen" || actionObj.action === "passErrorToOnboarding") && screenShareRef.current){
          screenShareRef.current.emitterCallback(actionObj);
      }

    })
  }

  const syncEmitterCallback = syncDoc => {
      if(syncDoc.currentStep) {
        if (syncDoc.currentStep === "PreparingSession" && preparingRef.current) {
          preparingRef.current.emitterCallback(syncDoc);
        } else if (syncDoc.currentStep === "ProctorReview"  && reviewRef.current) {
          reviewRef.current.emitterCallback(syncDoc);
        } else if (syncDoc.currentStep === "UtilityAppCheck" && utilityRef.current) {
          utilityRef.current.emitterCallback(syncDoc);
        }
      }
  }

    useEffect(() => {
      const header = document.querySelector('header');

      if (header) {
        header.focus();
      }
    }, [currentStep])

  useEffect(()=>{
    // Hide the support buttons once we pass preparing session step
    if(steps && currentStep) {
      if(currentStep > steps?.findIndex(step => step.title === 'preparing_session.title')) {
        if (window.zE) {
          window.zE.hide();
        } else {
          hideSupportChat();
        }
      }
    }

    if (showError) {
      const modalDescription = document.getElementById('modal-description');
      if (modalDescription) {
        modalDescription.focus();
      }

      document.addEventListener('keydown', lockModalFocus)
    } else {
      document.removeEventListener('keydown', lockModalFocus)
    }
  })

  // Check to see if the URL params we need have been passed in
  if(!params.token || !params.sessionSid || !params.installSid || !params.examSid ) {
    return (
      <SessionStatusError
          sessionStatus={'Malformed'}
      />
    );
  }

  if (sessionStatus && sessionStatus !== 'Scheduled') {
      return (
          <SessionStatusError
              sessionStatus={sessionStatus}
          />
      );
  }

  if (extensionError) {
      return (
          <ExtensionError
              buttonLogic={quietCloseExtension}
              extensionErrorValue={extensionError}
          />
      )
  }

  if(!steps){
      return;
  }

  const postHogOptions = {
      api_host: config.posthogHost
  };

    // Get the current step and render it
    const step = steps[currentStep];

    return (
        <>
            <PostHogProvider apiKey={config.posthogKey} options={postHogOptions}>
                <LaunchDarkly clientId={config.ldApiKey} user={ldConfig}>
                    <div className='stepper-container'>
                        <aside className='left'></aside>
                        <main>
                            {currentStep < steps.length &&
                                <>
                                    <header role='heading'  className='sp-heading' aria-level='1'  tabIndex='-1'>{translation(step.title)}</header>
                                    {step.component}
                                </>
                            }
                            {systemCheck && systemCheckComplete &&
                              <>
                                <header role='heading' className='sp-heading' aria-level='1' tabIndex='-1'>{translation('general.system_check.heading')}</header>
                                <div className='column'>
                                  <icons.iconCheckCircle />
                                  <p className="center-text">{translation('general.system_check.text')}</p>
                                </div>
                              </>
                            }
                        </main>
                        <aside className='right'>
                            <button
                              className='btn next-button'
                              onClick={nextStep}
                              disabled={!nextEnabled}
                              aria-hidden={!nextEnabled}
                            >
                                <icons.iconNext />
                                <p>{translation("general.next_button")}</p>
                            </button>
                        </aside>
                    </div>

                    <FeatureFlag
                      flagKey='salesforce-chat'
                      renderFeatureCallback={() => renderSupportChat(true)}
                      renderDefaultCallback={() => renderSupportChat(false)}
                    />

                    {showError &&
                      <div className='modal-container'>
                        <span className='modal-overlay'></span>
                        <div className='modal-content error'>
                          <strong className='modal-header'>{translation("general.error")}</strong>
                          <p className='meta' id='modal-description' tabIndex={-1}>{translation("general.modal_description")}</p>
                          <p id='error-desc'>{errorDescription}</p>

                          <button
                            className='btn btn--right'
                            id='close-modal'
                            aria-describedby='error-desc'
                            onClick={closeError}
                          >
                            {translation("general.close")}
                          </button>
                        </div>
                      </div>
                    }

                    {loading.length > 1 &&
                      <div className='modal-container'>
                        <span className={`modal-overlay ${whiteout ? 'whiteout' : ''}`} id='loading-overlay' />
                        <div className='loader'>
                          <strong className="loader__msg"><span className="meta">{translation("general.loading")}</span></strong>
                        </div>
                      </div>
                    }
                </LaunchDarkly>
            </PostHogProvider>
        </>
    )
}
import axios from 'axios';
import axiosRetry from 'axios-retry';

export let config = {
    apiUrl: 'http://localhost:8011/v1',
    speedTestServer: 'https://speedtest.smarterservices.com',
    chromeDownloadLink: 'https://www.google.com/chrome/downloads/',
    supportScriptUrl: 'https://help.dev.smarterservices.com/embed/in-app-widget.js',
    desktopAppInfo: {
        baseUrl: 'http://localhost:58729',
        mac:{
            downloadName: "SmarterServicesUtility_latest_Setup.dmg",
            url: 'https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases-stage/mac/latest/SmarterServicesUtility_latest_Setup.dmg'
        },
        manifest: 'https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases-stage/manifest.json',
        windows: {
            downloadName: "SmarterServicesUtility_Setup.exe",
            url: 'https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases-stage/windows/latest/SmarterServicesUtility_latest_Setup.exe'
        }
    },
    extensionDownloadLink: 'https://chromewebstore.google.com/detail/smarterproctoring/njmamkocphdenkffaliamlfabafhmpjl',
    extErrorMap: {
        OTHER_SESSION_ACTIVE: 'Extension active with another session',
        SAME_SESSION_ACTIVE: 'Extension active with the session already'
    },
    ldApiKey: '59edfdfd082f980b153ac148',
    logRocket: 'bkdp5p/onboarding-qqrdf',
    mac_permissions_pdfs:{
        pre_ventura: "https://smarterservices-pdfs.s3.amazonaws.com/mac_permissions_pre_ventura.pdf",
        ventura: "https://smarterservices-pdfs.s3.amazonaws.com/mac_permissions_ventura.pdf"

    },
    posthogKey: "phc_7cVscyp2pGRzB1u50N0jKu4CrASF760BzDaOPmEqcFt",
    posthogHost: "https://app.posthog.com",
    spExtensionId: 'njmamkocphdenkffaliamlfabafhmpjl',
    spExtensionsToDisable: [
        {id: "niffbjhghfgccacnebkhbpmgbnhocapg", name: ".*", description: ".*"},
        {id: "jojmghhfemggnfdmnpihldicgpjhigja", name: ".*", description: ".*"}
    ],
    ssDownloadRequirement: 3000,
    ssUploadRequirement: 1000
}

if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    config.apiUrl = 'https://api.dev.smarterproctoring.com/v1';
}

if (process.env.REACT_APP_ENVIRONMENT === 'sub') {
    config.apiUrl = 'https://api.sub.smarterproctoring.com/v1';
}

if (process.env.REACT_APP_ENVIRONMENT === 'stage') {
    config.apiUrl = 'https://api.stage.smarterproctoring.com/v1';
}

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    config.speedTestServer= 'https://speedtest.smarterservices.com';
    config.apiUrl = 'https://api.smarterproctoring.com/v1';
    config.supportScriptUrl = 'https://help.smarterservices.com/embed/in-app-widget.js';
    config.ldApiKey = '55b25a2f133767270f000092'
    // If we change the extensionId, we NEED to also make sure we update the extension download link, and make sure we
    // are disabling the proper extensions as well!
    config.spExtensionId = 'niffbjhghfgccacnebkhbpmgbnhocapg';
    config.extensionDownloadLink = 'https://chromewebstore.google.com/detail/smarterproctoring/niffbjhghfgccacnebkhbpmgbnhocapg';
    config.posthogKey = "phc_s1oXRy9jMGGJWsEj7HAFPH882jMMs1SbjpC3ZDkBImy";
    config.spExtensionsToDisable = [
        {id: "njmamkocphdenkffaliamlfabafhmpjl", name: ".*", description: ".*"},
        {id: "jojmghhfemggnfdmnpihldicgpjhigja", name: ".*", description: ".*"}
    ];
    config.desktopAppInfo = {
        baseUrl: 'http://localhost:58729',
        windows: {
            downloadName: "SmarterServicesUtility_Setup.exe",
            url: 'https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/windows/latest/SmarterServicesUtility_latest_Setup.exe'
        },
        mac:{
            downloadName: "SmarterServicesUtility_latest_Setup.dmg",
            url: 'https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/mac/latest/SmarterServicesUtility_latest_Setup.dmg'
        },
        manifest: 'https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/manifest.json'
    };
}

export let api = axios.create({
    baseURL: config.apiUrl
});

axiosRetry(api, {
    retries: 5,
    retryCondition(error) {
        return !!(error.response && error.response.status >= 400 && error.response.status < 600);
    },
    retryDelay: axiosRetry.exponentialDelay
});
